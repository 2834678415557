
import request from '@/router/axios';

// 我的申请列表
export const getMyList = (pageNumber,pageSize) => request({
  url: '/api/blade-remote/oneSubject/myApplyList',
  method: 'get',
  params:{
    current:pageNumber,
    size:pageSize
  }
})
// 向我申请列表
export const getToMeList = (pageNumber,pageSize) => request({
  url: '/api/blade-remote/oneSubject/applyListToMe',
  method: 'get',
  params:{
    current:pageNumber,
    size:pageSize
  }
})
